.maintainSignin {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 1000000;
}

.humor {
  font-style: italic;
  color: #444;
}

.file-types {
  display: none;
}

.topBar {
  position: fixed;
  top: 0%;
  height: 50px;
  width: 90%;
  z-index: 100;
  background-color: #EEEEEE;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
}
.exportButton {
}
.backLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.backArrow {
  font-size: 30px;
}
.backArrow a:link {
  text-decoration: none;
}
.backArrow a:visited {
  text-decoration: none;
}
.backArrow a:hover {
  text-decoration: none;
}
.backArrow a:active {
  text-decoration: none;
}
.transcriptContent {
  margin-left: 5%;
  margin-right: 35%;
  margin-top: 60px;
}

.emphasized {
  font-weight: bold;
  color: #00B4FF;
}

.highlighted {
  background-color: #888800;
}

.noted {
  background-color: #888800;
  text-decoration: underline;
  border-radius: 8px;
  overflow: hidden;
}
.noted .emphasized {
  text-decoration: underline;
  text-decoration-color: #00B4FF;
}

.questioned {
  background-color: #006a28;
  border-radius: 8px;
  overflow: hidden;
}
.questioned .emphasized {
  text-decoration: underline;
  text-decoration-color: #00B4FF;
}

.sidebarBubble {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.selectionActionMenu {
  background-color: #DDD;
  position: absolute;
  left: 70%;
  border-style: solid;
  border-radius: 8px;
  border-color: #EEE;
}
.actionMenuHidden {
  display: none;
}
.note {
  position: absolute;
  left: 70%;
  width: 25%;
  display: flex;
  background-color: #DDD;
  border-style: solid;
  border-radius: 8px;
  border-color: #EEE;
}
.note form {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
}
.note textarea {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  resize: none;
  border-radius: 8px;
}
.note button {
  margin-top: 1px;
  margin-right: 1px;
  margin-left: 2px;
  background-color: red;
  color: white;
  border-radius: 16px;
  position: relative;
  top: 0px;
  right: 0px;
  width: 20px;
  height: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1px;
  font-size: 11px;
}
.question {
  position: absolute;
  left: 70%;
  width: 25%;
  display: flex;
  background-color: #DDD;
  border-style: solid;
  border-radius: 8px;
  border-color: #EEE;
}
.question form {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}
.question textarea {
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  resize: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
}
.question textarea[readonly] {
  color: graytext;
  background: buttonface;
}
.question .questionButtons {
  display: flex;
  flex-direction: row;
  flex: 0;
}
.question button {
  background-color: red;
  color: white;
  flex: 1;
}
.question input[type=submit] {
  background-color: #006a28;
  color: white;
  flex: 1;
}
.stickToBottom {
  height: 1px;
  width: 100%;
}
